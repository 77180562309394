import 'bulma/css/bulma.min.css'

import { createApp } from 'vue'
import App from './App.vue'


const app = createApp(App)

app.config.globalProperties.$hscm = {
  appId: '389158e1-a629-4352-9726-979f6a2fe44f',
  root: process.env.VUE_APP_HASHSTORAGE_ROOT,
  hsc: null,
  api: null,
  profile: null,
}

app.mount('#app')
