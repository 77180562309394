<template>
  <div v-if="isReady">
    <h1 class="title px-3">Text app with Hashstorage</h1>

    <div class="px-3">
      <textarea class="textarea" v-model="text"></textarea>
      <br>
      <button class="button is-primary" @click="saveClick()">Save</button>
    </div>

    <div class="modal" :class="{'is-active': isLoginModalShown}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Login or Register</p>
        </header>
        <section class="modal-card-body">
          <input class="input my-1" type="text" placeholder="Username" 
                 v-model="username" />
          <input class="input my-1" type="password" placeholder="Password" 
                 v-model="password" />
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="login()">
            Login or Register
          </button>
        </footer>
      </div>
    </div>

    <div class="modal" :class="{'is-active': isRegisterModalShown}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Registration</p>
        </header>
        <section class="modal-card-body">
          No record found with entered username and password. 
          Do you want to create a new profile?
        </section>
        <footer class="modal-card-foot">
          <div class="buttons">
            <button class="button is-primary" @click="register()">
              Yes, create a new profile, please.
            </button>
            <button class="button is-white" @click="loginAgain()">
              No, enter username and password again.
            </button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
  import aes256 from 'aes256'
  import { ref, getCurrentInstance  } from 'vue'

  export default {
    setup() {
      const app = getCurrentInstance()
      const hscm = app.appContext.config.globalProperties.$hscm

      const isReady = ref(false)
      const isLoginModalShown = ref(false)
      const isRegisterModalShown = ref(false)
      const username = ref("")
      const password = ref("")
      const text = ref("")
      const block = ref(null)

      async function login() {
        if ((this.username != "") && (this.password != "")) {
          // Create a new profile instance
          hscm.profile = hscm.hsc.Profile.new(
            hscm.appId, this.username, this.password
          )

          // Try to load block
          const success = await this.loadBlock()

          if (success) {
            // Save profile into localStorage
            hscm.profile.save()

            // Close login popup
            this.isLoginModalShown = false
          } else {
            // Close login popup
            this.isLoginModalShown = false

            // Show register popup
            this.isRegisterModalShown = true
          }
        }
      }

      function loginAgain() {
        // Close register popup
        this.isRegisterModalShown = false

        // Show login popup
        this.isLoginModalShown = true
      }

      async function register() {
        // Save profile into localStorage
        hscm.profile.save()

        // Create an empty block
        this.block = hscm.hsc.Block.new(
          hscm.profile.publicKey(), "group_default", "key_default"
        )

        // Save empty block
        await this.saveBlock()

        // Close the modal
        this.isRegisterModalShown = false
      }

      async function loadBlock() {
        try {
          // Try to fetch the block of the data
          const blockJson = await hscm.profile.getBlockJson(
            hscm.api, "group_default", "key_default"
          )

          // Create a block instance
          this.block = hscm.hsc.Block.fromBlockJson(blockJson)

          // Get encrypted data from the block
          const encryptedText = this.block.data()

          // Decrypt the data with the private key
          this.text = aes256.decrypt(
            hscm.profile.privateKey(), encryptedText
          ).trim()

          // Return true in the end
          return true
        } catch (err) {
          // If block not found return false
          if (err.status == 404) {
            return false
          } else {
            throw err
          }
        }
      }

      async function saveBlock() {
        // Encrypt the text with the private key
        const encryptedText = aes256.encrypt(
          hscm.profile.privateKey(),
          this.text + ((this.text.length) ? '' : ' ')
        )

        // Set the data in the block
        this.block.setData(encryptedText)

        // Save the block
        await this.block.save(hscm.api, hscm.profile)
      }
      
      async function saveClick() {
        // Save current block
        await this.saveBlock()
      }

      return { 
        isReady,
        isLoginModalShown,
        isRegisterModalShown,
        username,
        password,
        text,
        block,

        login,
        loginAgain,
        register,
        loadBlock,
        saveBlock,
        saveClick,
      }
    },
    async mounted() {

      // Wait for preparing of Hashstorage entities
      const interval = setInterval(async () => {
        if (this.isReady) {
          // Stop interval
          clearInterval(interval)

          // If no profile suggest to log in
          if (this.$hscm.profile === null) {
            this.isLoginModalShown = true
          }
          else {
            // Try to load block
            const success = await this.loadBlock()

            // If block not found create and save an empty one
            if (!success) {
              this.block = this.$hscm.hsc.Block.new(
                this.$hscm.profile.publicKey(), "group_default", "key_default"
              )
              await this.saveBlock()
            }
          }
        }
      }, 1)
    },
    async beforeCreate() {
      // Define the imported library and api instance
      this.$hscm.hsc = await import('hashstorage-cli')
      this.$hscm.api = this.$hscm.hsc.Api.new(this.$hscm.root)

      // Check profile
      if (this.$hscm.hsc.Profile.exists()) {
        // Load profile from localStorage
        const profile = this.$hscm.hsc.Profile.load()

        // Check whether the profile is valid
        if (profile.check()) {
          // Set the profile entity
          this.$hscm.profile = profile
        }
        else {
          // Clear if it is not valid
          profile.clear()
        }
      }

      this.isReady = true
    },
  }
</script>
